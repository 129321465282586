@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .typography {
    a[href] {
      @apply font-semibold underline hover:text-info-primary;
    }

    strong {
      @apply font-semibold;
    }
  }

  body {
    @apply text-body1 text-foundation-primary;
  }
}

@layer utilities {
  input[type='number'].remove-spin-buttons::-webkit-inner-spin-button,
  input[type='number'].remove-spin-buttons::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'].remove-spin-buttons {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  input[type='search'].remove-search-decoration::-webkit-search-decoration,
  input[type='search'].remove-search-decoration::-webkit-search-cancel-button,
  input[type='search'].remove-search-decoration::-webkit-search-results-button,
  input[type='search'].remove-search-decoration::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .rich-text-block ul {
    @apply list-disc;
  }
}
